import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import ButtonUnderlined from '../components/ButtonUnderlined';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import HomepageSquiggle from '../images/assets/homepageHeaderSquiggle.svg';
import LazyImage from '../components/lazy-image';
import { Fade } from 'react-slideshow-image';

//TEMP IMPORTS
import homepagePlaceholder from '../images/tmp/homepagePlaceholder.png';
import convertHtml from '../utils/convertHtml';
import { CarouselProvider } from 'pure-react-carousel';
import Squiggle from '../components/squiggle';

const HomePageSliderContainerOuter = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const HomepageSliderContainer = styled.div`
  display: flex;
  height: 100vh;
  max-height: 1300px;
  width: 100%;
  @media (max-width: 1050px) {
    display: none;
    background-color: transparent;
  }
`;

const TextContentContainerOuter = styled(motion.div)`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
`;

const TextContentContainer = styled(motion.div)`
  width: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 2;
  margin-top: ${props => props.marginTopVal};
  @media (min-width: 1500px) {
    justify-content: flex-start;
  }
  @media (max-width: 1050px) {
    height: calc(50% - 120px);
    padding-top: 120px;
    width: 100%;
  }
`;

const TextContentInnerContainer = styled.div``;

const CaseStudyContainer = styled(motion.div)`
  width: 50%;
  height: 100%;
  background-color: #212121;
  z-index: 2;
  overflow: hidden;
  @media (max-width: 1050px) {
    width: 100%;
    height: 50%;
  }
`;

const Title = styled(motion.h1)`
  font-family: 'DM Serif Text';
  font-weight: 400;
  font-size: 65px;
  font-style: normal;
  line-height: 74px;
  color: ${props => props.color};
  max-width: 570px;
  margin-bottom: 30px;
  @media (max-width: 1520px) {
    font-size: 55px;
    line-height: 64px;
    max-width: 90%;
    margin-left: 5%;
  }
  @media (max-width: 1200px) {
    font-size: 35px;
    line-height: 44px;
    max-width: 90%;
    margin-left: 5%;
  }
  @media (max-width: 1050px) {
    font-size: 55px;
    line-height: 60px;
    max-width: 90%;
    margin-left: 5%;
    margin-bottom: 30px;
  }
  @media (max-width: 550px) {
    font-size: 28px;
    line-height: 32px;
  }
`;

const ImageContainerOuter = styled(motion.div)`
  height: calc(100% - 95px);
  width: 100%;
  background-color: #eee;
  overflow: hidden;
  @media (max-width: 1050px) {
    height: calc(100% - 65px);
  }
`;

const ImageContainer = styled(motion.div)`
  height: 101%;
  width: 100%;
  position: relative;
`;

const ActionBarContainer = styled(motion.div)`
  width: 100%;
  height: 95px;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  @media (max-width: 1050px) {
    justify-content: flex-start;
    padding-left: 5%;
    height: 65px;
  }
`;

const ActionBarButtonContainer = styled(motion.div)`
  margin-right: 55px;
`;

const ButtonContainer = styled(motion.div)`
  @media (max-width: 1520px) {
    margin-left: 5%;
  }
`;

const SquiggleContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  z-index: 5;
`;

const Subtitle = styled(motion.div)`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: ${props => props.color};
  max-width: 80%;
  margin-bottom: 30px;
  @media (max-width: 1520px) {
    max-width: 90%;
    margin-left: 5%;
  }
  @media (max-width: 1200px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 1050px) {
    max-width: 90%;
    margin-left: 5%;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const TextContainerAnim = {
  hidden: { height: 0 },
  visible: {
    height: '100%',
    transition: {
      delay: 0,
      duration: 0.45,
      ease: 'easeOut',
    },
  },
};

const CaseStudyContainerAnim = {
  hidden: { height: 0, y: '100vh' },
  visible: {
    height: '100%',
    y: '0',
    style: { marginTop: '0' },
    transition: {
      delay: 0.2,
      duration: 0.3,
      ease: 'easeOut',
    },
  },
};

const CaseStudyActionBarAnim = {
  hidden: {
    width: '0.001%',
  },
  visible: {
    width: '100%',
    transition: {
      delay: 0.5,
      duration: 0.3,
      ease: 'easeOut',
    },
  },
};

const CaseStudyTextAnim = {
  hidden: {
    opacity: 0,
    y: -25,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.7,
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

const CaseStudyButtonAnim = {
  hidden: {
    opacity: 0,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.8,
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

const CaseStudyActionBarButtonAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 1.1,
      duration: 0.2,
    },
  },
};

const ImageContainerAnim = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.5,
      duration: 0.35,
    },
  },
};

const CaseStudySubtitleAnim = {
  hidden: {
    opacity: 0,
    y: -25,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.8,
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

{
  /* MOBILE STYLES */
}

const HomepageSliderContainerMobile = styled.div`
  display: none;
  @media (max-width: 1050px) {
    display: block;
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    min-height: 900px;
  }
`;

const CarouselImage = styled.img`
  object-fit: cover;
  height: 100vh;
  width: 100%;
  background-color: #eee;
  overflow: hidden;
  top: -2.5%;
  left: -2.5%;
  width: 105%;
  object-fit: cover;
  object-position: center center;
  @media (max-width: 1050px) {
    height: 60vh;
  }
  @media (max-height: 650px) {
    height: 100%;
  }
`;

const CarouselWrapper = styled.div`
  .react-slideshow-fade-images-wrap {
    height: 100%;
  }

  .image-container {
    height: auto;
  }
`;

const fadeProperties = {
  duration: 5000,
  transitionDuration: 750,
  infinite: true,
  indicators: false,
  arrows: false,
  autoplay: true,
  onChange: (oldIndex, newIndex) => {},
};

export class homepageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <HomePageSliderContainerOuter>
            <HomepageSliderContainer>
              <TextContentContainerOuter
                variants={TextContainerAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <TextContentContainer
                  marginTopVal={
                    this.props.pageData.display_review_bar ? '40px' : '0px'
                  }
                >
                  <TextContentInnerContainer>
                    <Title
                      variants={CaseStudyTextAnim}
                      initial="hidden"
                      animate={this.state.visible ? 'visible' : 'hidden'}
                      dangerouslySetInnerHTML={convertHtml(
                        this.props.title_text.text
                      )}
                    />

                    <Subtitle
                      dangerouslySetInnerHTML={convertHtml(
                        this.props.subtitle_text.text
                      )}
                      variants={CaseStudySubtitleAnim}
                      initial="hidden"
                      animate={this.state.visible ? 'visible' : 'hidden'}
                    ></Subtitle>
                    <ButtonContainer
                      variants={CaseStudyButtonAnim}
                      initial="hidden"
                      animate={this.state.visible ? 'visible' : 'hidden'}
                    >
                      <ButtonUnderlined
                        buttonText={this.props.cta_text.text}
                        link={this.props.cta_link.url}
                        color={this.props.cta_color}
                        target={this.props.cta_link_target}
                      />
                    </ButtonContainer>
                  </TextContentInnerContainer>
                </TextContentContainer>
              </TextContentContainerOuter>
              <CaseStudyContainer
                variants={CaseStudyContainerAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <ImageContainerOuter>
                  <ImageContainer
                    variants={ImageContainerAnim}
                    initial="hidden"
                    animate={this.state.visible ? 'visible' : 'hidden'}
                  >
                    <CarouselWrapper>
                      <Fade {...fadeProperties}>
                        {this.props.items.map((item, index) => (
                          <div className="each-fade" style={{ height: '100%' }}>
                            <div
                              className="image-container"
                              style={{ height: '100%' }}
                            >
                              <CarouselImage
                                src={item.carousel_image.url}
                                alt={item.carousel_image.alt}
                              />
                            </div>
                          </div>
                        ))}
                      </Fade>
                    </CarouselWrapper>
                  </ImageContainer>
                </ImageContainerOuter>
                <ActionBarContainer
                  variants={CaseStudyActionBarAnim}
                  initial="hidden"
                  animate={this.state.visible ? 'visible' : 'hidden'}
                >
                  <ActionBarButtonContainer
                    variants={CaseStudyActionBarButtonAnim}
                    initial="hidden"
                    animate={this.state.visible ? 'visible' : 'hidden'}
                  >
                    <ButtonNotUnderlined
                      buttonText={this.props.case_study_cta_text.text}
                      color={'#FFFFFF'}
                      link={this.props.case_study_cta_link.url}
                      arrowColor={this.props.case_study_color_theme}
                      target={this.props.case_study_cta_link_target}
                    />
                  </ActionBarButtonContainer>
                </ActionBarContainer>
              </CaseStudyContainer>
            </HomepageSliderContainer>
          </HomePageSliderContainerOuter>
          <HomepageSliderContainerMobile>
            <TextContentContainer>
              <TextContentInnerContainer>
                <Title color="#FFF">{this.props.title_text.text}</Title>
                <Subtitle
                  color="#FFF"
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.subtitle_text.text
                  )}
                ></Subtitle>
                <ButtonContainer>
                  <ButtonUnderlined
                    buttonText={this.props.cta_text.text}
                    link={this.props.cta_link.url}
                    color={this.props.cta_color}
                    target={this.props.cta_link_target}
                  />
                </ButtonContainer>
              </TextContentInnerContainer>
            </TextContentContainer>
            <CaseStudyContainer>
              <ImageContainerOuter>
                <ImageContainer>
                  <CarouselWrapper>
                    <Fade {...fadeProperties}>
                      {this.props.items.map((item, index) => (
                        <div className="each-fade" style={{ height: '100%' }}>
                          <div
                            className="image-container"
                            style={{ height: '100%' }}
                          >
                            <CarouselImage
                              src={item.carousel_image.url}
                              alt={item.carousel_image.alt}
                            />
                          </div>
                        </div>
                      ))}
                    </Fade>
                  </CarouselWrapper>
                </ImageContainer>
              </ImageContainerOuter>
              <ActionBarContainer>
                <ActionBarButtonContainer>
                  <ButtonNotUnderlined
                    buttonText={this.props.case_study_cta_text.text}
                    color={'#FFFFFF'}
                    link={this.props.case_study_cta_link.url}
                    arrowColor={this.props.case_study_color_theme}
                    target={this.props.case_study_cta_link_target}
                  />
                </ActionBarButtonContainer>
              </ActionBarContainer>
            </CaseStudyContainer>
          </HomepageSliderContainerMobile>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default homepageHeader;
